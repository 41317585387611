// Logo styling
// ------------
.logo {
  text-align: left;
  padding-top: 16px;
  @media (max-width: 991px) {
    img {
      margin-top:5px;
      height: auto;
      display: block;
      max-width: 144px;
    }
  }
  @media (max-width: 767px) {
    text-align: center;
    padding-top: 16px;
    img {
      height: auto;
      display: block;
      max-width: 180px;
    }
  }
  a {
    display: inline-block;
  }
}
