.cta-wrapper {
  padding: 0 15px 60px 15px;
  text-align: center;
  background: #F3F3F3;
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    max-width: 900px;
    margin: 0 auto;
    margin-top:40px;
  }
  .cta-button {
    display: inline-block;
    background-color: #33BBE9;
    color: #FFFFFF;
    border-radius: 4px;
    font-weight: bold;
    font-size: 24px;
    line-height: 60px;
    height: 60px;
    max-width: 260px;
    width: 100%;
    margin-top: 40px;
    transition: background-color .2s ease-in-out;
    &:hover {
      background-color: darken(#33BBE9, 10%);
    }
  }
}

@media (max-width: 768px) {
  .cta-wrapper {
    p {
      font-size: 16px;
    }
    .cta-button {
      font-size: 18px;
      line-height: 40px;
      height: 40px;
      width: auto;
      padding: 0 20px;
    }
  }

}
