// Button styling
// --------------
.button {
  @include border-radius(4px);
  // @include box-shadow(0 1px 1px 1px rgba(0,0,0, 0.28));
  display: inline-block;
  width: 360px;
  height: 50px;
  line-height: 48px;
  .api-section & {
    font-size: $font-larger;
    font-weight: 400;
    margin-bottom: 43px;
  }
  .header & {
    font-size: $font-small;
    font-weight: 700;
    margin-bottom: 0;
    width: auto;
    padding:0 20px;
    height: 30px;
    line-height: 28px;
    margin-top: 19px;
    text-align: center;
    &.button-subtle {
      @include transition(background 0.2s ease-in-out);
      background-color:$kr-red;
      &:hover,
      &:active,
      &:focus {
        background: darken($kr-red, 10%);
        color: $white;
      }
    }
    @media (max-width: 991px) {
      padding:0 10px;
      width:auto;
      font-size:11px;
    }
  }
  &-purple-light {
    @include transition(background 0.2s ease-in-out);
    background: $button-bgnd;
    color: $gray-darker;
    @media (max-width: 767px) {
      width: 100%;
    }
    &:hover,
    &:active,
    &:focus {
      background: darken($button-bgnd, 10%);
      text-decoration: none !important;
      color: $gray-darker !important;
    }
  }
  &-transparent {
    @include transition(all 0.2s ease-in-out);
    background: transparent;
    color: $gray-darker;
    border: 1px solid $subscribe;
    text-decoration:none !important;
    &:hover,
    &:active,
    &:focus {
      background: #fefefe;
      color: $gray-darker !important;
    }
  }
  &-blue {
    @include transition(all 0.2s ease-in-out);
    background: $blue-button;
    color: $white;
    &:hover,
    &:active,
    &:focus {
      background: darken($blue-button, 10%);
      color: $white;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  &-red {
    @include transition(all 0.2s ease-in-out);
    background: red;
    color: $white;
    &:hover,
    &:active,
    &:focus {
      background: darken(red, 10%);
      color: $white;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  &-gray {
    @include transition(all 0.2s ease-in-out);
    background: lightgray;
    color: #5bb7d6;
    &:hover,
    &:active,
    &:focus {
      background: darken(lightgray, 10%);
      color: #5bb7d6;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

}
