.client-logos {
  padding: 0 30px;
  .logos-list {
    display: inline-flex;
    justify-content: center;
    position: relative;
    padding: 0;
    max-width: 1110px;
    margin: 0 auto 4px;
    list-style-type: none;
    border-bottom: 1px solid #E5E5E5;
    li {
      height: 80px;
      .logo-item {
        display: inline-block;
        padding: 0 12px;
        border-bottom: 2px solid transparent;
        height: 100%;
        display: flex;
        align-items: center;
        img {
          max-height: 51px;
          max-width: 100%;
//          -webkit-filter: grayscale(100%);
//          filter: grayscale(100%);
          opacity: 0.8;
          transition: opacity .4s ease-in-out;
        }
        &.active,
        &:hover {
          img {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
            opacity: 1;
          } 
        }
        &.active {
          border-color:#192C39;
        }
        &.small {
          img {
            max-height: 45px;
          }
        }
        &.large {
          img {
            max-height: 57px;
          }
        }
      }
    }
    &::before {
      content: 'Select Customers';
      position: absolute;
      left: -200px;
      top: 50%;
      transform: translateY(-50%);
      height: auto;
      font-size: 18px;
      font-weight: 400;
      color: #AAA;
      font-weight: 900;
    }
  }
  .bx-wrapper {
    border: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    margin: 0 auto;
    background: transparent;
    .logos-list {
      display: block;
      max-width: none;
      height: calc(100% - 4px);
      margin: 0 auto;
      li {
        height: 80px;
        width: auto !important;
        max-width: 180px;
        .logo-item {
          height: calc(100% - 4px);
          padding: 0 12px;
        }
      }
    }
    .bx-controls-direction a {
      text-indent: 0;
      font-size: 36px;
      top: 50%;
      transform: translateY(-50%);
      color: #C4C4C4;
      &:hover {
        color: #505656;
      }
    }
    .bx-next,
    .bx-prev {
      background: transparent;
    }
    .bx-prev {
      left: -30px;
    }
    .bx-next {
      right: -30px;
    }
    .bx-controls-direction a.disabled {
      display: block;
      i {
        color: rgb(236, 236, 236);
      }
    }
  }
}

.tab-content {
  background: rgba(243, 243, 243, 0.5);
  position: relative;
  top: -4px;
  .tab-pane {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    max-width: 1170px;
    margin: 0 auto;
    &-wrapper {
      display: flex;
      padding: 30px 0;
      .tab-pane-content {
        max-width: 556px;
        text-align: left;
        margin-right: 8%;
        p {
          margin-bottom: 10px;
          color: #787878;
        }
        .tab-title {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }
        .tab-link a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .tab-pane-image {
        flex: 0 1 30%;
      }
      .tab-pane-image,
      .tab-pane-icon {
        img {
          margin: 15px;
        }
      }
    }
    &.active {
      opacity: 1;
    }
  }
}

.select-customers {
  display: none;
  position: absolute;
  left: 50%;
  top: -25px;
  transform: translateX(-50%);
  font-size: 18px;
  font-weight: 400;
  color: #AAA;
  font-weight: 900;
}

.gradient-section {
  background: -moz-linear-gradient(top,  rgba(255,255,255,0), rgba(255,255,255,.3));
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0),rgba(255,255,255,.3));
  background: linear-gradient(to bottom,  rgba(255,255,255,0), rgba(255,255,255,.3));
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}

@media (max-width: 1200px) {
  .client-logos .logos-list::before {
    left: -160px;
  }
}

@media (max-width: 1170px) {
  .tab-content {
    padding: 0 10px;
  }
}

@media (max-width: 1130px) {
  .client-logos .logos-list::before {
    left: 50%;
    top: -30px;
    transform: translateY(0);
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .tab-content {
    padding: 0 20px;
    .tab-pane-wrapper {
      padding: 20px 0;
    }
    .tab-pane-image,
    .tab-pane-icon {
      display: none;
    }
  }
  .client-logos {
    position: relative;
    .logos-list::before {
      display: none;
    }
  }
  .select-customers {
    display: block;
  }
}
