.share-link {
  @include transition(background 0.2s ease-in-out);
  display:inline-block;
  color:$white;
  background:lighten($gray-light, 20%);
  width:34px;
  height:34px;
  margin:20px 6px;
  text-align:center;
  @include border-radius(18px);
  i {
    font-size:14px;
    line-height:34px;
  }
  &:hover,
  &:active,
  &:focus {
    background:$gray-light;
    color: $white;
  }
}

.popover {
  border-color:rgba(0,0,0,.1);
  @include box-shadow(0 2px 5px rgba(0,0,0,.15));
}

.popover.left > .arrow,
.popover.top > .arrow, {
  border-left-color:rgba(0,0,0,.25);
}