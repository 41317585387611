.post#content-page {
  .blog-sidebar {
    margin-top: 50px;
  }
  h1 {
    font-weight: normal;
    font-size: 30px;
    line-height: 38px;
    color: #505656;
    padding-right: 40px;
    margin-bottom: 7px;
  }
  h2 {
    margin-bottom: 30px;
    font-weight: normal;
    color: #686868;
  }
  h3 {
    font-size: 18px;
    color: #686868;
    font-weight: bold;
    margin-bottom: 7px !important;
  }
  p {
    margin-bottom: 30px;
  }
  ul, ol {
      margin-top:-15px;
      margin-bottom: 30px;
  }
  ul {
    margin-left: 0;
    padding-left: 35px;
  }
  li::before {
    color: #33BBE9;
    width: 18px;
    margin-left: -18px;
  }
  ol li::before {
    display: none;
  }
  .article-header {
    margin-bottom: 35px;
    h3 {
      margin-bottom: 16px;
    }
    .info {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #787878;
    }
  }
  blockquote h4 {
    margin-bottom: 0;
  }
  img {
    display: block;
    max-width: 100%;
  }
  p img + em {
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 22px;
    color: #787878;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .anchorjs-link {
    display: none;
  }
}

@media (max-width: 768px) {
  #content-page.post {
    h1, p {
      padding-right: 0;
    }
  }
}
