#tg-sb-content {
  min-height:70vh;
  text-align:left;
  
  ul li {
    line-height:1.6em;
    padding-bottom:0.5em;
    font-size:16px;
    list-style-type:none;
    list-style-image: url('../images/bullet.png');
    p {
      margin-bottom:0;
      padding-bottom:0;
    }
    h4.panel-title {
	font-size:14px;
    }
  }
  h1 {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 5px;
    margin: .67em 0 1.2em 0;
    font-size: 30px;
    color: #555;
    font-weight: 300;
  }
  h2 {
    font-size: 24px;
    font-weight: 300;
    font-color: #777;
    margin: 1em 0 .3em 0;
  }
  dt {
    font-weight: 700;
  }

  dd, dt {
    line-height: 1.4em;
  }

  dl, dd {
    margin: 10px 0;
  }

  dd {
    margin-left: 20px;
  }
  hr.faded {
    display:none;
  }
  p {
    margin-bottom: 10px;
  }
}

#main {
  padding:80px 0 120px 0;
}

.menu li {
  margin-right:22.5px;
}

@media(max-width:991px) {
  #tg-sb-content {
    min-height:auto;
  }
}

h4.panel-heading {
    background-color: #ddd;
    border-color: #222;
}

#mysidebar.nav,
#mysidebar.nav ul,
#mysidebar.nav li {
  list-style: none;
  border-radius: 0;
  padding:0;
  margin:0 0 0 0;
  font-size:13px;
  text-align:left;
}

#mysidebar.nav {
  margin-top:34px;
  margin-bottom:42px;
  width:260px;
  a {
    color: #333;
    display: block;
    outline: none;    
  }
}

#mysidebar.nav li > a > span {
    float: left;
    padding-right: 8px;
    font-weight: bolder;
    color: #999;
    margin-top: -2px;
}

#mysidebar.nav li {
  min-height:26px;
}

#mysidebar.nav li > a > span:after {
    content: '\25b8';
}

#mysidebar.nav li > a:hover > span:after {
    content: '\25b8';
    color: #039be5;    
}

#mysidebar.nav li.active > a > span:after {
    content: '\25be';
    color: #039be5;
    font-weight:bold;
}

#mysidebar.nav > li li.active > a  {
  color: #039be5;
  font-weight:bold;
}
#mysidebar.nav > li li > a:hover  {
  color: #039be5 !important;
}

#mysidebar.nav li ul {
  padding: 2px 0 4px 0;
  background-color: #FAFAFA;
}

#mysidebar.nav li a {
  line-height: 18px;
  background-color: #f1f1f1;
  padding: 4px 0 0 10px;
}

#mysidebar.nav > li > a {
    line-height: 26px;
}

#mysidebar.nav li.thirdlevel > a {
    color: #248EC2;
    font-weight:bold;
    padding-left:20px;
    background-color: whitesmoke !important;
}

#mysidebar.nav ul li a {
    background-color: #FAFAFA;
}

#mysidebar.nav li a {
    padding-right:10px;
}

#mysidebar.nav ul li a {
    padding-left:27px;
}


@media(max-width:1199px) {
  #mysidebar.nav {
    width:230px;
  }
}

@media(max-width:990px) {
  #mysidebar.nav {
    position:relative;
    width:100%;
  }
  
}

/* Inline highlights and perks */

a[data-toggle="tooltip"] {
    color: #649345;
    font-style: italic;
    cursor: default;
}

/* Table of Contents */

div#toc ul li {
    margin: 8px 0px 0px 22px;
    padding:0;
    font-size: 90%;
    list-style: none;
    line-height:1.4em;
}

div#toc ul {
    background-color: whitesmoke;
    padding: 10px 5px 20px 10px;
    border-radius: 5px;
    max-width: 320px;
    color: gray;
    margin-bottom:20px;
}

div#toc ul li ul {
    padding-left:8px;

}


div#toc ul li ul li::before {
    content: "–  ";
}

div#toc >ul::before {
    content: "Table of Contents";
    font-weight: bold;
    color: #555;
    text-align:center;
    margin:0 auto;
    width:70px;
    padding: 20px 0 20px 10px;
}

/* Highlight */

.highlight {
  background: #fafafa;
  .c { color: #999988; font-style: italic } /* Comment */
  .err { color: #a61717; background-color: #e3d2d2 } /* Error */
  .k { font-weight: bold } /* Keyword */
  .o { font-weight: bold } /* Operator */
  .cm { color: #999988; font-style: italic } /* Comment.Multiline */
  .cp { color: #999999; font-weight: bold } /* Comment.Preproc */
  .c1 { color: #999988; font-style: italic } /* Comment.Single */
  .cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
  .gd { color: #000000; background-color: #ffdddd } /* Generic.Deleted */
  .gd .x { color: #000000; background-color: #ffaaaa } /* Generic.Deleted.Specific */
  .ge { font-style: italic } /* Generic.Emph */
  .gr { color: #aa0000 } /* Generic.Error */
  .gh { color: #999999 } /* Generic.Heading */
  .gi { color: #000000; background-color: #ddffdd } /* Generic.Inserted */
  .gi .x { color: #000000; background-color: #aaffaa } /* Generic.Inserted.Specific */
  .go { color: #888888 } /* Generic.Output */
  .gp { color: #555555 } /* Generic.Prompt */
  .gs { font-weight: bold } /* Generic.Strong */
  .gu { color: #aaaaaa } /* Generic.Subheading */
  .gt { color: #aa0000 } /* Generic.Traceback */
  .kc { font-weight: bold } /* Keyword.Constant */
  .kd { font-weight: bold } /* Keyword.Declaration */
  .kp { font-weight: bold } /* Keyword.Pseudo */
  .kr { font-weight: bold } /* Keyword.Reserved */
  .kt { color: #445588; font-weight: bold } /* Keyword.Type */
  .m { color: #009999 } /* Literal.Number */
  .s { color: #d14 } /* Literal.String */
  .na { color: #008080 } /* Name.Attribute */
  .nb { color: #0086B3 } /* Name.Builtin */
  .nc { color: #445588; font-weight: bold } /* Name.Class */
  .no { color: #008080 } /* Name.Constant */
  .ni { color: #800080 } /* Name.Entity */
  .ne { color: #990000; font-weight: bold } /* Name.Exception */
  .nf { color: #990000; font-weight: bold } /* Name.Function */
  .nn { color: #555555 } /* Name.Namespace */
  .nt { color: #000080 } /* Name.Tag */
  .nv { color: #008080 } /* Name.Variable */
  .ow { font-weight: bold } /* Operator.Word */
  .w { color: #bbbbbb } /* Text.Whitespace */
  .mf { color: #009999 } /* Literal.Number.Float */
  .mh { color: #009999 } /* Literal.Number.Hex */
  .mi { color: #009999 } /* Literal.Number.Integer */
  .mo { color: #009999 } /* Literal.Number.Oct */
  .sb { color: #d14 } /* Literal.String.Backtick */
  .sc { color: #d14 } /* Literal.String.Char */
  .sd { color: #d14 } /* Literal.String.Doc */
  .s2 { color: #d14 } /* Literal.String.Double */
  .se { color: #d14 } /* Literal.String.Escape */
  .sh { color: #d14 } /* Literal.String.Heredoc */
  .si { color: #d14 } /* Literal.String.Interpol */
  .sx { color: #d14 } /* Literal.String.Other */
  .sr { color: #009926 } /* Literal.String.Regex */
  .s1 { color: #d14 } /* Literal.String.Single */
  .ss { color: #990073 } /* Literal.String.Symbol */
  .bp { color: #999999 } /* Name.Builtin.Pseudo */
  .vc { color: #008080 } /* Name.Variable.Class */
  .vg { color: #008080 } /* Name.Variable.Global */
  .vi { color: #008080 } /* Name.Variable.Instance */
  .il { color: #009999 } /* Literal.Number.Integer.Long */
}
