blockquote {
  background: #F3F3F3;
  padding: 30px 20px;
  margin: 0 0 30px;
  border-left: none;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #3C3C3C;
  clear: both;
  overflow: hidden;
  p {
    display: flex;
    margin-bottom: 21px;
    &::before {
      content: '\f10d';
      display: block;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-style: normal;
      font-size: 51px;
      line-height: 22px;
      color: #E5E5E5;
      margin-right: 20px; 
    }
  }
  h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: #787878;
    text-align: right;
    margin-right: 40px;
    float: right;
    padding-top: 5px;
    border-top: 1px solid #C4C4C4;
  }
}

@media (max-width: 768px) {
  blockquote h4 {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  blockquote p {
    display: block;
    &::before {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
}