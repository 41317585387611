// Navigation styling
// ------------------
.menu {
  text-align:left;
  @extend %reset-list;
  margin-top: 22px;
  @media (max-width: 991px) {
    font-size: 13px;
    margin-top: 24px;
  }
  li {
    display: inline-block;
    text-transform:uppercase;
    margin-right: 22px;
    @media (max-width: 991px) {
      margin-right: 5px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    @include transition(color 0.2s ease-in-out);
    color: #777;
    font-weight:700;
    &.active {
      color: #111;
      // border-bottom:2px solid $kr-yellow;
    }
    &:hover {
      color: #222;
      // border-bottom:2px solid $kr-yellow;
    }
  }
}


// 991
// 767