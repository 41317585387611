#promo-carousel {
  padding-top: 36px;
  background: $promo-bg;
  border-top: 1px solid $promo-border;
  border-bottom: 1px solid $promo-border;
  h3 {
    font-size: 24px;
    font-style: italic;
    min-height: 100px;
    color: $promo-text-color;
    padding:0 220px;
    @media (max-width: 991px) {
      padding:10px 10px 0 10px;
      font-size:20px;
      min-height: 100px;
    }
    @media (max-width: 767px) {
      padding:10px 10px 0 10px;
      font-size:20px;
      min-height: 140px;
    }
    strong {
      color: $promo-text-color-strong;
      font-weight: normal;
      background-image: linear-gradient(to bottom,  $promo-bg, rgba(252, 239, 172, .7), 65%, $promo-bg); //#fab900, #80B7E8 rgb(51, 187, 233, 0.5)
      background-position: 0% 100%;
      background-repeat: repeat-x;
    }
  }
  .carousel-indicators li {
    border-color:#999;
    margin: 0px;
    width:12px;
    height:12px;
    @include opacity(0.7);
    &.active {
      width:12px;
      height:12px;
      margin-top: 1px;
      background-color:$kr-blue;
      border-color:$kr-blue;
      // background-color:rgb(231, 65, 52);
      // border-color:rgb(231, 65, 52);
      @include opacity(1);
    }
  }
  // make carousel fade in/out
  .carousel-inner {
    .item {
      transition-property: opacity;
    }
    .item,
    .active.left,
    .active.right {
      opacity: 0;
    }
    .active,
    .next.left,
    .prev.right {
      opacity: 1;
    }
    .next,
    .prev,
    .active.left,
    .active.right {
      left: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  
}