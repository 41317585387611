// Declare all mixins here //
// --------------------------

// Typography style mixin
// ----------------------
@mixin heading-font ($size, $line, $weight: bold) {
  font-size: $size;
  font-weight: $weight;
  line-height: $line;
}

// Position mixin
// --------------
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Border radius mixin
// -------------------
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Transform mixin
// ---------------
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}
