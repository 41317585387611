// Embed styling
// -------------
.embed-wrap {
  position: relative;
  padding-bottom: 56%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  border: 1px solid $gray-border;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
