// --- Font definitions
    $line: 1.5;
    $font-title: 48px;
    $font-subtitle: 24px;
    $font-base: 16px;
    $font-large: 18px;
    $font-larger: 20px;
    $font-small: 14px;


// --- Web fonts

    $font-alpha: 'Oxygen', sans-serif;

// --- Colors

    $white: #fff;
    $black: #000;
    $red: #ff0000;

    $button-bgnd:#f3f3f3;
    $button-bgnd-darker:#e9e9e9;

    $aqua-light: #84ffff;

    $gray-border: #bababa;

    $gray: #414141;
    $gray-dark: #4d4d4d;
    $gray-darker: #212121;
    $gray-light: #505656;
    $gray-lighter: #cfdcdc;

    $sublabel-text-color: #787878;

    $gray-bgnd: #fafafa;

    $blue: #06c;
    $blue-button: #1e88e5;

    $subscribe: #adadad;
    $bullet-color: #cccccc;
    $applynow-color: #3fc0f0;
    $share-color:#575756;
  
// --- Footer

    $footer-text: #9a9e9e;

// --- Brand colors

    $kr-red:#e93f33;
    $kr-yellow:#ffba09;
    $kr-blue:#33bbe9;

// --- Promo
    $promo-bg: #f9fafb; //rgba(163,221,169,0.4); rgba(254,217,197,0.4);
    $promo-text-color:  #6f7475;
    $promo-text-color-strong:  #666666;
    $promo-border: #ececec;

// Defaults
    $base-width: 1170px;
