// Convincer styling
// -----------------
.convincer {
  @include position(relative);
  // This CSS declaration is for repeating pattern as background
  // background: url('../images/convincer-bgnd-gray.png');

  // These 2 CSS declaration should be used when large image is background
  background: url('../images/convincer.jpg') no-repeat center 280px;
  background-attachment: fixed;
  background-size: contain;
  background-position:80px 235px;
  background-color: $white;

  // height: 90vh; //vertical height size
  // max-height:650px;
  padding: 164px 0 0 0;

  @media (max-width: 991px) {
    // max-height:600px;
    background-position:0 360px;
  }

  @media (max-width: 767px) {
    padding: 50px 0 0 0;
    background-attachment: scroll;
    background-position:0 200px;
    height:auto;
  }

  .gradient-section {
      padding: 161px 0 0 0;
  }
  h1 {
    padding: 0 200px;
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  h3 {
    @include heading-font($font-subtitle, $line, 400);
    color: $sublabel-text-color;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #787878;
    margin-bottom: 60px;
  }
  
  .button {
    font-size: $font-subtitle;
    font-weight: 700;
    margin-bottom: 32px;
    background:$kr-blue;
    // height updates
    padding-bottom:4px;
    height:auto;
    width:260px;
    // height updates end
    // new button style
    color: $white;
//    border:1px solid #ccc;
//    background:rgba(255,255,255, 0.8);
    // new button style end
    &:hover,
    &:active,
    &:focus {
      background: darken($kr-blue, 10%);
//      border-color:#aaa;
//      color: #666;
    }
    
  }
  
}


@media (max-width: 767px) {
  #getting-started {
    display:none;
  }
  #schedule-a-call {
    font-size:16px;
    width:auto;
    padding:0 20px 2px 20px;
    height:auto;
    line-height:30px;
  }
}

