// Media block styling
// -------------------
.media-block {
  .icon-wrap {
    @include border-radius(50%);
    width: 140px;
    height: 140px;
    margin: 0 auto 11px;
    border: 1px solid #d1d1d1;
    @media (max-width: 767px) {
      width: 120px;
      height: 120px;
    }
    img {
      @media (max-width: 767px) {
        width:100px !important;
        height:100px !important;
        margin-top:10px;
      }
    }
    .large-icons & {
      border: none;
      background: $button-bgnd;
      i {
        font-size: 80px;
        color: $black;
        line-height: 140px;
        @media (max-width: 767px) {
          font-size: 60px;
          line-height: 120px;
        }
      }
    }
    .empty-icons & {
      border: none;
    }
    .features-section & {
      width: auto;
      height: 100px;
    }
  }
  .large-icons & {
    margin-bottom: 73px;
  }
  h3 {
    line-height: 1.3;
    font-weight: 700;
    color: $gray-darker;
    font-size: $font-subtitle;
    margin-bottom: 5px;
    .why-section &,
    .features-section & {
      font-size: $font-large;
      @media (max-width: 767px) {
        font-size: $font-subtitle;
      }
    }
    .video-wrap & {
      text-align: left;
      margin-top: 13px;
    }
  }
  p {
    color: $gray-light;
    font-size: $font-larger;
    line-height: 1.3;
    .why-section &,
    .features-section & {
      font-size: $font-base;
      @media (max-width: 767px) {
        font-size: $font-larger;
      }
    }
    .video-wrap & {
      text-align: left;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 50px;
    .empty-icons & {
      margin-bottom: 20px;
    }
  }
  h3, p, .icon-wrap {
    transition: color .2s ease-in-out, background-color .2s ease-in-out;
  }
  > a:hover {
    h3 {
      color: #33BBE9;
    }
    p {
      color: #ADADAD;
    }
    .icon-wrap {
      background: #E9E9E9;
    }
  }
}

.media-wrap {
  .features-section & {
    margin-bottom: 56px;
  }
}