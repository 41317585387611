.blog {
  &#content-page {
    h1 {
      font-weight: 400;
      font-size: 36px;
//      line-height: 61px;
//      margin-bottom: 56px;
    }
    .blog-post {
      padding-bottom: 30px;
      margin-right: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #E5E5E5;
      h2 a {
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #505656;
        transition: color .2s ease-in-out;
        &:hover {
          color: #33BBE9;
        }
      }
      p {
        padding-right: 0;
      }
      a {
        font-size: 14px;
        line-height: 18px;
        &.anchorjs-link {
          display: none;
        }
      }
      .info {
        font-size: 14px;
        line-height: 18px;
        color: #787878;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .excerpt {
      margin-bottom: 20px;
      p {
        display: inline;
      }
    }
    &.category {
      padding-top: 91px;
    }
  }
}

.blog-sidebar {
  margin-bottom: 38px;
  p {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #787878;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #E5E5E5;
  }
  #content-page &,
  .post#content-page & {
    ul {
      padding: 0;
      margin: 0;
      li {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        a {
          font-size: 16px;
          line-height: 20px;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  #content-page.blog {
    h1 {
      font-size: 36px;
      margin-bottom: 30px;
    }
    h2 {
      font-size: 24px;
    }
  }
}
