// Typography configuration
// ------------------------

h1,h2, h3, h4, h5, h6, p {
  @extend %reset-space;
}

h1 {
  @include heading-font($font-title, 1.2, 700);
  color: $gray-light;
  font-weight: 400;
  margin-bottom: 25px;
  @media (max-width: 767px) {
    font-size: 28px;
  }
}

h2 {
  @include heading-font($font-title, $line, 400);
  color: $gray-light;
  font-weight: 300;
  @media (max-width: 767px) {
    font-size: 30px;
  }
}

h3 {
  @include heading-font($font-subtitle, $line, 700);
  color: $gray-darker;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

a {
  color: $kr-blue;
  &:hover,
  &:active,
  &:focus {
    color: darken($kr-blue, 10%);
    text-decoration: none;
  }
}

p a {
  text-decoration:none;
  &:hover,
  &:active,
  &:focus {
    color: darken($kr-blue, 10%);
    text-decoration: underline;
  }
  
}