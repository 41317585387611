// Footer block styling
// --------------------

.footer {
  background: $gray;
  font-size: $font-small;
  padding: 40px 0 39px;
  text-align:left;
  color: $footer-text;
  a {
    color:lighten($footer-text,15%);
    &:hover {
      text-decoration: underline;
      color:lighten($footer-text,15%);
    }
  }
  h5 {
    color: #ccc;
    font-size: 15px;
    font-weight:bold;
    margin-bottom: .5em;
  }
  ul {
    padding-left:0;
    list-style-position:inside;
    margin-bottom:20px;
  }
  p {
    padding-right:40px;
    margin-bottom:20px;
    &.about {
      padding-right:75px;
    }
  }
  @media (max-width: 767px) {
    h3, ul, p {
      margin-left:20px;
    }
  }
}
