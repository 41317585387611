.features-section {
  #content-page & {
    background: #fff;
    padding-top: 0;
    .media-block {
      padding-bottom: 35px;
      border-bottom: 1px solid #E5E5E5;
      margin-bottom: 30px;
      overflow: hidden;
      clear: both;
      .icon-wrap {
        float: left;
        max-width: 150px;
        margin: 0;
        width: 100%;
        height: auto;
      }
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
        margin-left: 150px;
      }
    }
  }
  #content-page.use-cases & {
    h2 {
      margin-top: 30px;
    }
  }
}

#content-page.use-cases {
  h1 {
    font-size: 36px;
    font-weight: 400;
  }
  h2 {
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 23px;
    margin-top: 60px;
  }
  h3 {
    font-size: 18px;
    color: #505656;
    font-weight: bold;
  }
  h2.subtitle {
    margin-top: 0;
    margin-bottom: 30px;
    color: #787878;
  }
  p {
    margin-bottom: 30px;
  }
  ul, ol {
    margin-bottom: 30px;
  }
  ul {
    margin-left: 0;
    padding-left: 35px;
  }
  ul.requirements-list {
    padding-left: 20px;
    li {
      margin-bottom: 0;
      strong {
        color: #3C3C3C;
      }
      &::before {
        display: none;
      }
    }
  }
  li::before {
    color: #33BBE9;
    width: 18px;
    margin-left: -18px;
  }
  ol li::before {
    display: none;
  }
  .side-logos {
    &-title {
      text-align: center;
      color: #787878;
      font-weight: normal;
      font-size: 20px;
      line-height: 25px;
      padding: 0;
      max-width: 190px;
      margin: 0 auto 36px;
      padding-bottom: 15px;
      border-bottom: 1px solid #E5E5E5;
    }
    &-list {
      margin: 0;
      padding: 0;
      text-align: center;
      li {
        padding: 0;
        margin-bottom: 65px;
        p {
          padding: 0;
          color: #787878;
          margin-top: 8px;
          font-size: 14px;
        }
        a {
          display: block;
          text-decoration: underline;
        }
        &::before {
          display: none;
        }
        img {
          max-width: 161px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1140px) {
  #content-page.use-cases .side-logos-list li img {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  #content-page.use-cases {
    h1 {
      font-size: 36px;
      margin-bottom: 30px;
    }
    h2 {
      font-size: 24px;
    }
    .side-logos-title {
      max-width: 360px;
    }
    .side-logos {
      margin-top: 60px;
    }
    .side-logos-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        padding: 0 12px;
        margin-bottom: 20px;
        flex: 1 1 33.333333%;
      }
    }
  }
  #content-page .features-section .media-block {
    padding-bottom: 20px;
    margin-bottom: 20px;
    p {
      font-size: 16px;
      margin-left: 130px;
    }
    .icon-wrap {
      flex: 0 0 130px;
      max-width: 130px;
      img {
	margin-top: 0;
        height: 110px !important;
        width: 110px !important;
      }
    }
  }
}

@media (max-width: 480px) {
  #content-page .features-section .media-block {
    display: flex;
    flex-wrap: wrap;
    .icon-wrap {
      float: none;
      order: 1;
    }
    h3 {
      flex: 1 1 100%;
      margin-bottom: 15px;
    }
    p {
      order: 2;
      margin-left: 0;
      padding-right: 0;
      flex: 0 0 calc(100% - 130px);
    }
  }
}

@media (max-width: 400px) {
  #content-page.use-cases {
    .side-logos-list {
      li {
        padding: 0 15px;
        margin-bottom: 10px;
        flex: 1 1 100%;
      }
    }
  }
}
