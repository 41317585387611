#content-page {
  background: url('../images/content/content-bg-color.png') no-repeat center 0;
  background-size: contain;
  background-position:0 0;
  background-color: $white;
  text-align: left;
  margin-top: 70px;
  color:#3c3c3c;
  margin-bottom:40px;
  h1 {
    margin-top:50px;
    font-size:36px;
    font-weight:400;
  }
  h2 {
    font-size:24px;
    font-weight:400;
    margin-bottom:0.5em;
  }
  p {
    margin-bottom: 1.3em;
    line-height: 1.5em;
    padding-right: 40px;
    &.location {
      font-weight: 700;
    }
    &.sidenote {
      font-style: italic;
      color:#777;
    }
  }
  ul {
    list-style: none;
    margin-left:2em;
  }
  li {
    line-height: 1.3em;
    margin-bottom: 1.2em;
    padding-right: 40px;
  }
  li::before {
    content: "•";
    color: $bullet-color;
    display: inline-block;
    width: 2em;
    margin-left: -2em;
  }
  .apply-now {
    clear:both;
    min-width:137px;
    float:right;
    display: block;
    text-align: center;
    color: $white;
    background: $applynow-color;
    font-weight: 700;
    font-size: 14px;
    padding: 0 20px;
    height: 30px;
    line-height: 29px;
    margin-bottom:40px;
    @include border-radius(4px);
    @include transition(background 0.2s ease-in-out);
    &:hover,
    &:active,
    &:focus {
      background: darken($applynow-color, 10%);
      color: $white;
    }
    @media (max-width: 767px) {
      display:inline-block;
    }
  }
  .share {
    cursor:pointer; 
    @include transition(background 0.2s ease-in-out);
    @include opacity(0.8);
    clear:both;
    font-size:14px;
    float:right;
    margin-top:0px;
    margin-bottom:30px;
    display:block;
    background:url('/images/icons/share.png') no-repeat scroll 0 0;
    background-size:34px 34px;
    text-transform:uppercase;
    font-weight:700;
    height:34px;
    padding-left:40px;
    margin-right:28px;
    color:$share-color;
    line-height:34px;
    &:hover,
    &:active,
    &:focus {
      @include opacity(1);
    }
    @media (max-width: 767px) {
      display:none;
    }
  }
  @media (max-width: 767px) {
    .share, .apply-now {
      float:none;
      max-width:138px;
    }
    .apply-now {
      margin-top:10px;
    }
    p, li {
      padding-right:20px;
    }
    background-position:0 0;
    background-attachment: scroll;
    margin-top:0;
    .side-buttons {
      text-align:center;
    }
    ul {
      margin-left:-1.2em;
    }
    li::before {
      width: 1.2em;
      margin-left: -1.2em;
    }
   }
}
