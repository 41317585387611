// Note styling
// ------------
.note {
  background: $button-bgnd-darker;
  padding: 33px 0 36px;
  p {
    padding: 0 160px;
    line-height: 1.3;
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  a {
    color: $gray-darker;
    &:hover {
      text-decoration: underline;
    }
  }
}
