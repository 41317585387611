// Subscribe styling
// -----------------
.subscribe {
  margin-bottom: 70px;
  .api-section & {
    .button {
      @include box-shadow(none);
      font-size: $font-large;
      width: 107px;
      height: 32px;
      line-height: 28px;
      margin: 0;
    }
  }
  .form-wrap {
    display: none;
    margin-top: 20px;
  }
  #mc_embed_signup {
    font-size: $font-small;
    line-height: 1.3;
    margin-top: 20px;
    .indicates-required {
      text-align: left;
      margin-bottom: 10px;
    }
    #mc-embedded-subscribe-form {
      padding: 0;
      div.mce_inline_error {
        background: none;
        padding: 5px 0;
        font-size: 12px;
        font-weight: 700;
        color: $red;
      }
      input.mce_inline_error {
        border: 2px solid $red;
      }
    }
    label {
      font-weight: 400;
      cursor: pointer;
    }
    .asterisk {
      color: $red;
    }
    .mc-field-group {
      padding-bottom: 0;
      margin-bottom: 15px;
      @media (max-width: 767px) {
        width: 100%;
      }
      &.input-group {
        margin-top: 30px;
        @media (max-width: 767px) {
          margin-top: 0;
          margin-bottom: 7px;
        }
      }
    }
    .button-blue {
      @include transition(all 0.2s ease-in-out);
      background: $blue-button;
      color: $white;
      padding: 0;
      height: 36px;
      line-height: 34px;
      width: 100%;
      margin-top: 21px;
      &:hover,
      &:focus {
        background: darken($blue-button, 10%);
        color: $white;
      }
    }
    #mce-responses {
      padding: 0;
      margin: 0 0 30px;
      font-size: $font-large;
      line-height: 1.5;
      width: 100%;
    }
    .response {
      margin: 0 auto;
      padding-top: 0;
      padding-right: 0;
      text-align: center;
      width: 80%;
      float: none;
    }
  }
}

.close-wrap {
  float: right;
  margin-top: 7px;
  @media (max-width: 767px) {
    float: none;
    text-align: center;
    margin-top: 0;
  }
}

.close-trigger {
  position: relative;
  z-index: 100;
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:hover {
    text-decoration: none !important;
    &::before,
    &::after {
      background: $blue;
    }
  }

  &::before,
  &::after {
    @include border-radius(5px);
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: $gray-darker;
  }
  &::before {
    @include transform(rotate(45deg));
  }
  &::after {
    @include transform(rotate(-45deg));
  }
}
