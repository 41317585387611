// Header styling
// --------------
.header {
  @include position(fixed, $top: 0, $right: 0, $left: 0);
  background-color: rgba(255,255,255, 0.94);
  z-index: 20;
  height: 70px;
  border-bottom:1px solid #ebebeb;
  &.full-color {
    background-color: rgba(255, 255, 255, 1);
  }
  @media (max-width: 767px) {
    @include position(static);
  }
}
