// Section wrap styling
// --------------------
.section-wrap {
  padding: 41px 0 0;
  .title {
    padding: 0 240px;
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  h2 {
    margin-bottom: 6px;
  }
  h3 {
    font-weight: 400;
    color: $sublabel-text-color;
    line-height: 1.3;
    margin-bottom: 32px;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  &.gradient-section {
    @media (max-width: 1170px) {
	padding: 100px 0 0;
    }
    @media (max-width: 767px) {
	padding: 20px 0 0;
    }
  }
  &.why-section {
    padding-bottom: 60px;
    @media (max-width: 767px) {
      padding-bottom: 20px;
    }
  }
  &.features-section {
    @include position(relative);
    z-index: 10;
    background: $button-bgnd;
    h2 {
      margin-bottom: 12px;
    }
    // fix layout for 5-columns
    .col5 {
      width:20%;
      @media (max-width: 767px) {
        width:100%;
      }
    }
  }
  &.use-cases-section {
    @include position(relative);
    z-index: 1;
    @include box-shadow(0 1px 5px 5px rgba(0,0,0, 0.13));
    h2 {
      margin-bottom: 22px;
    }
  }
  &.api-section {
    background: $button-bgnd;
    p {
      font-size: $font-large;
      line-height: 1.3;
      color: $gray-light;
      margin-bottom: 21px;
      padding:0 140px;
      @media (max-width: 767px) {
        font-size: $font-base;
        padding:0 20px;
      }
    }
    .subscribe {
      p {
        @extend %reset-space;
        padding:0 140px;
        @media (max-width: 767px) {
          font-size: $font-base;
          padding:0 20px;
        }
        // color: $gray-darker;
      }
    }
  }
}
