// Announcements styling
// ---------------------
.announcements {
  @include position(absolute, $right: 0, $bottom: 0, $left: 0);
  background: rgba(0,0,0, 0.79);
  padding: 18px 0 17px;
  a {
    color: $white;
  }
  span {
    color: $aqua-light;
    text-transform: uppercase;
    font-weight: 700;
  }
  i {
    color: $gray-lighter;
  }
}
